<div *ngIf="showProfile" class="row">
  <div class="col-12">
    <div class="border rounded p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center pb-2 mb-3 border-bottom">
        <h3 class="h6 fw-bold mb-0">Details</h3>
        <a *ngIf="isEdit" [routerLink]="['/employee/profile']" class="btn btn-primary btn-sm rounded-0">EDIT</a>
      </div>
      <div *ngIf="profile">
        <dl class="row">
          <dt class="col-sm-3">Shala Darpan (PIS) ID</dt>
          <dd class="col-sm-9">{{ profile.applicationUser.shalaId }}
          </dd>
          <dt class="col-sm-3">Name</dt>
          <dd class="col-sm-9">{{ profile.firstName + ' ' + profile.middleName + ' ' + profile.lastName }}
          </dd>
          <dt class="col-sm-3">DOJ Present Place</dt>
          <dd class="col-sm-9">{{ getServiceBook(profile.serviceBooks)?.durationFrom | date }}</dd>

          <dt class="col-sm-3">Designation</dt>
          <dd class="col-sm-9">{{ getServiceBook(profile.serviceBooks)?.designation?.name }}</dd>
          <dt class="col-sm-3">School/Place</dt>
          <dd class="col-sm-9">{{ getServiceBook(profile.serviceBooks)?.school?.name }}</dd>
        </dl>
      </div>
    </div>
  </div>
</div>

<div *ngIf="moreProfile" class="row">
  <div *ngIf="profile" class="col-12">
    <div class="border rounded p-3 mb-3">
      <dl class="row">
        <dt class="col-sm-3">Home State</dt>
        <dd class="col-sm-9">{{ profile.homeDistrict?.state.name }}</dd>

        <dt class="col-sm-3">Home District</dt>
        <dd class="col-sm-9">{{ profile.homeDistrict?.name }}</dd>

        <dt class="col-sm-3">Aadhar Card No.</dt>
        <dd class="col-sm-9">{{ profile.aadharCardNo }}</dd>

        <dt class="col-sm-3">Gender</dt>
        <dd class="col-sm-9">{{ profile.gender?.name }}</dd>

        <dt class="col-sm-3">Religion</dt>
        <dd class="col-sm-9">{{ profile.religion?.name }}</dd>

        <dt *ngIf="profile.otherReligion" class="col-sm-3">Other Religion</dt>
        <dd *ngIf="profile.otherReligion" class="col-sm-9">{{ profile.otherReligion }}</dd>

        <dt class="col-sm-3">NPS No</dt>
        <dd class="col-sm-9">{{ profile.npS_No }}</dd>

        <dt class="col-sm-3">CPF No</dt>
        <dd class="col-sm-9">{{ profile.cpF_No }}</dd>

        <dt class="col-sm-3">NVS Joining Date</dt>
        <dd class="col-sm-9">{{ profile.nvsJoiningDate | date }}</dd>

        <dt class="col-sm-3">Birth Date</dt>
        <dd class="col-sm-9">{{ profile.birthDate | date }}</dd>

        <dt class="col-sm-3">Retirement Date</dt>
        <dd class="col-sm-9">{{ profile.retirementDate | date }}</dd>

        <dt class="col-sm-3">Social Category</dt>
        <dd class="col-sm-9">{{ profile.category?.name }}</dd>

        <dt class="col-sm-3">Recruitment Category</dt>
        <dd class="col-sm-9">{{ profile.recruitmentCategory?.name }}</dd>

        <!-- <dt class="col-sm-3">Near Retirement ?</dt>
        <dd class="col-sm-9">{{ profile.isRetirementNear ? 'YES' : 'NO' }}</dd> -->

        <dt class="col-sm-3">Spec. Req. Drive</dt>
        <dd class="col-sm-9">{{ profile.isSpecReqDrive ? 'YES' : 'NO' }}</dd>

        <dt *ngIf="profile.appointRegion" class="col-sm-3">Appointed Region</dt>
        <dd *ngIf="profile.appointRegion" class="col-sm-9">{{ profile.appointRegion?.name }}</dd>

        <dt *ngIf="profile.specJoiningDate" class="col-sm-3">Date of joining (Spec. Req. Drive)</dt>
        <dd *ngIf="profile.specJoiningDate" class="col-sm-9">{{ profile.specJoiningDate | date }}</dd>
      </dl>
    </div>
  </div>
</div>

<div *ngIf="moreProfile" class="row">
  <div class="col-12">
    <div class="border rounded p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center pb-2 mb-3 border-bottom">
        <h3 class="h6 fw-bold mb-0">Service Records</h3>
        <a *ngIf="isEdit" [routerLink]="['/employee/service-book']" class="btn btn-primary btn-sm rounded-0">EDIT</a>
      </div>
      <div *ngIf="profile?.serviceBooks && profile.serviceBooks.length > 0" class="table-responsive">
        <table class="table align-items-center table-hover">
          <colgroup>
            <col style="width: 5%">
            <col style="width: 15%">
            <col style="width: 15%">
            <col style="width: 10%">
            <col style="width: 10%">
            <col style="width: 15%">
            <!-- <col style="width: 15%"> -->
            <col style="width: 15%">
          </colgroup>
          <thead class="table-light">
            <tr>
              <th>Sr No.</th>
              <th>School/Place</th>
              <th>Designation</th>
              <th>From</th>
              <th>To</th>
              <th>Posting Reason</th>
              <!-- <th>Transfer Category</th> -->
              <th>Deductible Leave/Absence(days)</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let v of profile.serviceBooks | orderBy:'durationFrom' : true; let i=index;">
              <td>{{ i + 1 }}</td>
              <td>{{ v.school?.name }}</td>
              <td>{{ v.designation?.name }}</td>
              <td>{{ v.durationFrom | date }}</td>
              <td>
                <span *ngIf="v.durationTo">{{ v.durationTo | date }}</span>
                <span *ngIf="!v.durationTo">Present</span>
              </td>
              <td>
                {{ v.transferReason?.name }}
                <span *ngIf="v.appointmentMode">{{ '('+ v.appointmentMode?.name + ')' }}</span>
              </td>
              <!-- <td>{{ v.transferCategory?.name }}</td> -->
              <td>{{ v.leaveCount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showOther" class="row">
  <div class="col-12">
    <div class="border rounded p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center pb-2 mb-3 border-bottom">
        <h3 class="h6 fw-bold mb-0">Disability Details</h3>
        <a *ngIf="isEdit" [routerLink]="['/employee/profile/other-details/disabled-info']"
          class="btn btn-primary btn-sm rounded-0">EDIT</a>
      </div>

      <div *ngIf="profile && profile.disabledInfos.length > 0">

        <dl class="row" *ngIf="profile.disabledInfos[0];let disabledInfo">
          <dt class="col-sm-4">Disability</dt>
          <dd class="col-sm-8">{{ disabledInfo.disability?.name }}</dd>

          <dt class="col-sm-4">Relation</dt>
          <dd class="col-sm-8">{{ disabledInfo.relation?.name }}</dd>

          <dt class="col-sm-4">Percentage</dt>
          <dd class="col-sm-8">
            {{ disabledInfo.percentage }}
          </dd>

          <dt class="col-sm-4">Certificate</dt>
          <dd class="col-sm-8">
            <a *ngIf="disabledInfo.disabilityCertId" target="_blank"
              [href]="disabledInfo.disabilityCert?.mediaUrl">Document</a>
          </dd>

          <dt class="col-sm-4 text-truncate">Cert. Issue Date</dt>
          <dd class="col-sm-8">
            {{ disabledInfo.certIssueDate | date }}
          </dd>

        </dl>

      </div>
      <div *ngIf="profile && profile.disabledInfos.length == 0" class="d-flex justify-content-center align-items-center"
        style="min-height: 100px;">
        <p>No Details</p>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="border rounded p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center pb-2 mb-3 border-bottom">
        <h3 class="h6 fw-bold mb-0">Serious ailment Details</h3>
        <a *ngIf="isEdit" [routerLink]="['/employee/profile/other-details/disease-info']"
          class="btn btn-primary btn-sm rounded-0">EDIT</a>
      </div>

      <div *ngIf="profile && profile.diseaseInfos.length > 0">


        <dl class="row" *ngIf="profile.diseaseInfos[0];let diseaseInfo">

          <dt class="col-sm-4">Disease</dt>
          <dd class="col-sm-8">
            {{ diseaseInfo.disease?.name }}
          </dd>

          <dt class="col-sm-4">Relation</dt>
          <dd class="col-sm-8">{{ diseaseInfo.relation?.name }}</dd>

          <dt class="col-sm-4">Certificate</dt>
          <dd class="col-sm-8">
            <a *ngIf="diseaseInfo.diseaseCertId" target="_blank" [href]="diseaseInfo.diseaseCert?.mediaUrl">Document</a>
          </dd>

          <dt class="col-sm-4 text-truncate">Disease Detection/Surgery Date</dt>
          <dd class="col-sm-8">
            {{ diseaseInfo.detectionDate | date }}
          </dd>

          <dt class="col-sm-4 text-truncate">Cert. Issue Date</dt>
          <dd class="col-sm-8">
            {{ diseaseInfo.certIssueDate | date }}
          </dd>

          <dt class="col-sm-4 text-truncate">Cert. issued/ countersigned by CHO/CMS/CS?</dt>
          <dd class="col-sm-8">
            {{ diseaseInfo.isValidCert ? 'YES' : 'NO' }}
          </dd>
        </dl>
      </div>
      <div *ngIf="profile && profile.diseaseInfos.length == 0" class="d-flex justify-content-center align-items-center"
        style="min-height: 100px;">
        <p>No Details</p>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="border rounded p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center pb-2 mb-3 border-bottom">
        <h3 class="h6 fw-bold mb-0">Single Lady Details</h3>
        <a *ngIf="isEdit && (profile.genderId == 2 && !isSpouse())"
          [routerLink]="['/employee/profile/other-details/single-women-info']"
          class="btn btn-primary btn-sm rounded-0">EDIT</a>
      </div>

      <div *ngIf="profile && profile.singleWomenInfos.length > 0">
        <dl class="row" *ngIf="profile.singleWomenInfos[0];let singleWomenInfo">

          <dt class="col-sm-4">Single Lady Type</dt>
          <dd class="col-sm-8">{{ getSingleLadyType(singleWomenInfo.singleLadyType) }}</dd>

          <dt class="col-sm-4">Certificate</dt>
          <dd class="col-sm-8">
            <a *ngIf="singleWomenInfo.deathCertId" target="_blank"
              [href]="singleWomenInfo.deathCert?.mediaUrl">Document</a>
          </dd>
        </dl>
      </div>

      <div *ngIf="profile && profile.singleWomenInfos.length == 0"
        class="d-flex justify-content-center align-items-center" style="min-height: 100px;">
        <p>No Details</p>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="border rounded p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center pb-2 mb-3 border-bottom">
        <h3 class="h6 fw-bold mb-0">Spouse Details (If NVS)</h3>
        <a *ngIf="isEdit && (!showNVSIn() && !isSingleWomen())"
          [routerLink]="['/employee/profile/other-details/spouse-in-info']"
          class="btn btn-primary btn-sm rounded-0">EDIT</a>
      </div>

      <div *ngIf="profile && profile.spouseInInfos.length > 0">
        <dl class="row" *ngIf="profile.spouseInInfos[0];let spouseInInfo">
          <dt class="col-sm-4">Email</dt>
          <dd class="col-sm-8">
            {{ spouseInInfo.email }}
          </dd>

          <dt class="col-sm-4">Service Type</dt>
          <dd *ngIf="spouseInInfo.jobTypeId" class="col-sm-8">{{ spouseInInfo.jobType?.name }}</dd>

          <dt class="col-sm-4">Certificate</dt>
          <dd class="col-sm-8">
            <a *ngIf="spouseInInfo.spouseCertId" target="_blank" [href]="spouseInInfo.spouseCert?.mediaUrl">Document</a>
          </dd>

          <dt class="col-sm-4 text-truncate">Cert. Issue Date</dt>
          <dd class="col-sm-8">
            {{ spouseInInfo.certIssueDate | date }}
          </dd>
        </dl>
      </div>

      <div *ngIf="profile && profile.spouseInInfos.length == 0" class="d-flex justify-content-center align-items-center"
        style="min-height: 100px;">
        <p>No Details</p>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="border rounded p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center pb-2 mb-3 border-bottom">
        <h3 class="h6 fw-bold mb-0">Spouse Details (If Out Of NVS)</h3>
        <a *ngIf="isEdit && (!showNVSOut() && !isSingleWomen())"
          [routerLink]="['/employee/profile/other-details/spouse-out-info']"
          class="btn btn-primary btn-sm rounded-0">EDIT</a>
      </div>

      <div *ngIf="profile && profile.spouseOutInfos.length > 0">
        <dl class="row" *ngIf="profile.spouseOutInfos[0];let spouseOutInfo">
          <dt class="col-sm-4">Name</dt>
          <dd class="col-sm-8">
            {{ spouseOutInfo.title + ' ' + spouseOutInfo.firstName + ' ' + spouseOutInfo.middleName + ' ' +
            spouseOutInfo.lastName }}
          </dd>

          <dt class="col-sm-4">Department</dt>
          <dd class="col-sm-8">{{ spouseOutInfo.jobPlaceType?.name }}</dd>

          <dt class="col-sm-4">Service Type</dt>
          <dd class="col-sm-8">{{ spouseOutInfo.jobType?.name }}</dd>

          <dt class="col-sm-4">Posting State and District</dt>
          <dd class="col-sm-8">
            {{ spouseOutInfo.district?.state?.name + ' - ' + spouseOutInfo.district?.name }}
          </dd>

          <dt class="col-sm-4">Email</dt>
          <dd class="col-sm-8">{{ spouseOutInfo.email }}</dd>

          <dt class="col-sm-4">Birth Date</dt>
          <dd class="col-sm-8">{{ spouseOutInfo.birthDate | date }}</dd>

          <dt class="col-sm-4">Certificate</dt>
          <dd class="col-sm-8">
            <a *ngIf="spouseOutInfo.spouseCertId" target="_blank"
              [href]="spouseOutInfo.spouseCert?.mediaUrl">Document</a>
          </dd>

          <dt class="col-sm-4 text-truncate">Cert. Issue Date</dt>
          <dd class="col-sm-8">
            {{ spouseOutInfo.certIssueDate | date }}
          </dd>
        </dl>
      </div>

      <div *ngIf="profile && profile.spouseOutInfos.length == 0"
        class="d-flex justify-content-center align-items-center" style="min-height: 100px;">
        <p>No Details</p>
      </div>
    </div>
  </div>

  <div class="col-12" *ngIf="profile.isRetirementNear">
    <div class="border rounded p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center pb-2 mb-3 border-bottom">
        <h3 class="h6 fw-bold mb-0">Due For Retirement (DFR)</h3>
      </div>
      <div *ngIf="profile && profile.retirementDate">
        <dl class="row">
          <dt class="col-sm-2">Retirement Date</dt>
          <dd class="col-sm-2">
            {{ profile.retirementDate | date }}
          </dd>
        </dl>
      </div>
      <div *ngIf="profile && profile.retirementDate == null" class="d-flex justify-content-center align-items-center"
        style="min-height: 100px;">
        <p>No Details</p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showPreference" class="row">
  <div class="col-12">
    <div class="border rounded p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center pb-2 mb-3 border-bottom">
        <h3 class="h6 fw-bold mb-0">Transfer Request Details</h3>
        <a *ngIf="isEdit" [routerLink]="['/employee/transfer-request']"
          class="btn btn-primary btn-sm rounded-0">EDIT</a>
      </div>

      <div *ngIf="transferRequest">
        <dl class="row">
          <dt class="col-sm-6">Do you wish to participate transfer process?</dt>
          <dd class="col-sm-6">
            {{ transferRequest.isTransfer ? "YES" : "NO" }}
          </dd>

          <dt class="col-sm-6" *ngIf="transferRequest.isTransfer">Willigness to serve at hard Station? (For Plain
            Station
            Employee)</dt>
          <dd class="col-sm-6" *ngIf="transferRequest.isTransfer">
            {{ transferRequest.isHardStation ? "YES" : "NO" }}
          </dd>
        </dl>
      </div>

      <div *ngIf="!transferRequest" class="d-flex justify-content-center align-items-center" style="min-height: 100px;">
        <p>No Details</p>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="border rounded p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center pb-2 mb-3 border-bottom">
        <h3 class="h6 fw-bold mb-0">Preferences</h3>
        <a *ngIf="isEdit && transferRequest && transferRequest.isTransfer" [routerLink]="['/employee/transfer-request/preference']"
          class="btn btn-primary btn-sm rounded-0">EDIT</a>
      </div>
      <div *ngIf="preferences" class="table-responsive">
        <table class="table align-items-center table-hover table-sm">
          <colgroup>
            <col style="width: 10%">
            <col style="width: 30%">
            <col style="width: 30%">
            <col *ngIf="isAdmin" style="width: 30%">
          </colgroup>
          <thead class="table-info">
            <tr>
              <th>Order</th>
              <th>School</th>
              <th>Type</th>
              <th *ngIf="isAdmin">Remark</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let v of preferences; let i=index;">
              <td>{{ v.order }}</td>
              <td>{{ v.vacantPost?.sanctionedPost?.school?.name }}</td>
              <td>{{ v.vacantPost.vacancyType?.name }}</td>
              <td *ngIf="isAdmin">{{ v.rejectRemark }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>
