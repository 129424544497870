<div class="wrapper">
    <div class="page-header error-page">
        <div class="page-header-image" style="background-image: url('/assets/img/bg/404.svg');"></div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h1 class="title">Unauthorized Access!</h1>
                    <p>Sorry, we've misplaced that URL or it's pointing to something that doesn't exist.</p>
                    <a class="btn btn-primary btn-wide transition-3d-hover" [routerLink]="['/home']">Go Home</a>
                </div>
            </div>
        </div>
    </div>
</div>