<nav *ngIf="totalRows > pageSize">
    <ul class="pagination justify-content-center mt-4">
        <li class="page-item" [class.disabled]="firstPage == currentPage">
            <a [routerLink]="[currentRoute]" [queryParams]="getPageParams(1)" class="page-link text-decoration-none">
                First
            </a>
        </li>
        <li class="page-item" [class.disabled]="currentPage == 1">
            <a [routerLink]="[currentRoute]" [queryParams]="getPageParams(prevPage)" class="page-link text-decoration-none">
                <span aria-hidden="true">&laquo;</span>
            </a>
        </li>
        <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage == page">
            <a [routerLink]="[currentRoute]" [queryParams]="getPageParams(page)" class="page-link text-decoration-none">{{ page }}</a>
        </li>
        <li class="page-item" [class.disabled]="currentPage == pages.length">
            <a [routerLink]="[currentRoute]" [queryParams]="getPageParams(nextPage)" class="page-link text-decoration-none">
                <span aria-hidden="true">&raquo;</span>
            </a>
        </li>
        <li class="page-item" [class.disabled]="lastPage == currentPage">
            <a [routerLink]="[currentRoute]" [queryParams]="getPageParams(lastPage)" class="page-link text-decoration-none">
                Last
            </a>
        </li>
    </ul>
</nav>