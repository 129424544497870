import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class SanctionedPostService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/sanctioned-posts', httpClient, toastrService);
  }

  getDesignations() {
    return this.httpClient.get(this.baseUrl + 'api/sanctioned-posts/designations')
      .pipe(catchError(this.handleError.bind(this)))
  }

  find(schoolId, designationId) {
    return this.httpClient.get(this.baseUrl + 'api/sanctioned-posts/find?schoolId=' + schoolId + '&designationId=' + designationId)
      .pipe(catchError(this.handleError.bind(this)))
  }

  delete(id) {
    return this.httpClient.post(this.baseUrl + 'api/sanctioned-posts/delete/' + id, {})
      .pipe(catchError(this.handleError.bind(this)))
  }

  export(filter) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.httpClient.get(this.baseUrl + 'api/sanctioned-posts/export' + '?' + this.toQueryString(filter), httpOptions)
      .pipe(catchError(this.handleError.bind(this)))
  }

}
