<nav class="navbar navbar-expand-lg navbar-dark bg-primary shadow-sm">
    <div class="container">
        <a class="navbar-brand mr-lg-4" [routerLink]="['/']">
            <i-bs name="house-door" width="1em" height="1em"></i-bs>
        </a>
        <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="navbar_global">
            <i-bs name="list" width="1em" height="1em"></i-bs>
        </button>
        <div class="collapse navbar-collapse" id="navbar_global" [ngbCollapse]="isCollapsed">
            <ul class="navbar-nav navbar-hover">
                <li *ngFor="let item of primaryMenuContents" class="nav-item">
                    <div class="dropdown-hover" *ngIf="item.childMenuContents && item.childMenuContents.length > 0"
                        ngbDropdown>
                        <a class="nav-link nav-link-icon fw-bold dropdown-toggle" href="javascript:;" ngbDropdownToggle
                            [attr.id]="'navbar-user_dropdown' + item.id" role="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <span class="mr-2">{{item.name}}</span>
                        </a>
                        <div [attr.aria-labelledby]="'navbar-user_dropdown' + item.id" ngbDropdownMenu>
                            <div *ngFor="let child of item.childMenuContents">
                                <app-menu-item [child]="child"></app-menu-item>
                            </div>
                        </div>
                    </div>
                    <a *ngIf="!item.childMenuContents || item.childMenuContents.length === 0 && !item.isExternalLink"
                        class="nav-link nav-link-icon fw-bold" [routerLink]="[item.url]">
                        <span class="mr-2">{{item.name}}</span>
                    </a>
                    <a *ngIf="!item.childMenuContents || item.childMenuContents.length === 0 && item.isExternalLink"
                        class="nav-link nav-link-icon fw-bold" [href]="item.url" target="_blank">
                        <span class="mr-2">{{item.name}}</span>
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav ml-lg-auto">
                <!-- <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link nav-link-icon fw-bold" [routerLink]="['/search']">
                       <i-bs name="search" width="1em" height="1em"></i-bs>
                    </a>
                </li> -->
                <li *ngIf="!isLoggedIn" class="nav-item">
                    <a class="nav-link nav-link-icon" [routerLink]="['/account/login']">
                        <i-bs name="person-fill" width="1em" height="1em"></i-bs>
                        <span class="ml-2">Sign In</span>
                    </a>
                </li>
                <app-user-bar *ngIf="isLoggedIn"></app-user-bar>
            </ul>
        </div>
    </div>
</nav>