<div class="wrapper">
    <div class="page-header error-page">
        <div class="page-header-image" style="background-image: url('/assets/img/bg/404.svg');"></div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h1 class="title">404</h1>
                    <p class="lead">Page not found :(</p>
                    <h4 class="description text-default">Ooooups! Looks like you got lost.</h4>
                    <a class="btn btn-primary btn-wide transition-3d-hover" [routerLink]="['/home']">Go Back</a>
                </div>
            </div>
        </div>
    </div>
</div>