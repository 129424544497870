<footer class="bg-light border border-top py-3">
  <div class="container">
    <div class="content">
      <div class="row">
        <div class="col-md-6">
          <ul class="pl-0">
            <li class="d-inline-block">
              <a [routerLink]="['/home/feedback']" routerLinkActive="active"
                class="nav-link text-default text-uppercase">
                Feedback
              </a>
            </li>
            <!-- <li class="d-inline-block">
              <a [routerLink]="['/page/privacy-policy']" routerLinkActive="active"
                class="nav-link text-default text-uppercase">
                Privacy Policy
              </a>
            </li>
            <li class="d-inline-block">
              <a [routerLink]="['/page/disclaimer']" routerLinkActive="active"
                class="nav-link text-default text-uppercase">
                Disclaimer
              </a>
            </li> -->
          </ul>
        </div>
        <div class="col-md-6">
          <div class="text-right text-default">
            Copyright ©<span>{{year}}</span> Navodaya Vidyalaya Samiti. All Rights Reserved.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="text-default">
            Navodaya Vidyalaya Samiti, is working under the MHRD, Government Of India, It was formed to provide good
            quality modern education-including a strong component of
            culture, inculcation of values, awareness of the environment, adventure activities and physical
            education- to the talented children predominantly from the rural areas without regard to their
            family's socio-economic conditions.
          </div>
        </div>
        <div class="col-md-3">
          <!-- <app-select-language></app-select-language> -->
        </div>
      </div>
    </div>
  </div>
</footer>