<div class="card card-shadow">
    <div class="card-header bg-default text-white fw-bold">
        Important Links
    </div>
    <div class="card-body p-0 scroll-thin" style="max-height: 482px;overflow-y:auto">
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <a class="text-dark" href="https://www.ncs.gov.in/">
                    NCS
                </a>
            </li>
            <li class="list-group-item">
                <a class="text-dark" href="https://nsdcindia.org/">
                    NSDC
                </a>
            </li>
            <li class="list-group-item">
                <a class="text-dark" href="https://www.msde.gov.in/">
                    Ministry of Skill Development And Entrepreneurship
                </a>
            </li>
            <li class="list-group-item">
                <a class="text-dark" href="https://skillindia.nsdcindia.org/">
                    Skill India
                </a>
            </li>
            <li class="list-group-item">
                <a class="text-dark" href="#">
                    India Skills
                </a>
            </li>
            <li class="list-group-item">
                <a class="text-dark" href="https://bharatskills.gov.in/">
                    Bharat Skills
                </a>
            </li>
            <li class="list-group-item">
                <a class="text-dark" href="https://msme.gov.in/">
                    MSME
                </a>
            </li>
            <li class="list-group-item">
                <a class="text-dark" href="https://labour.gov.in/">
                    Labour, GoI
                </a>
            </li>
            <li class="list-group-item">
                <a class="text-dark" href="https://employment.gujarat.gov.in/">
                    Employment
                </a>
            </li>
            <li class="list-group-item">
                <a class="text-dark" href="https://www.gsdm.com/">
                    GSDM
                </a>
            </li>
            <li class="list-group-item">
                <a class="text-dark" href="http://www.gcvt.org/">
                    GCVT
                </a>
            </li>
            <li class="list-group-item">
                <a class="text-dark" href="https://labour.gujarat.gov.in/">
                    Labour
                </a>
            </li>
            <li class="list-group-item">
                <a class="text-dark" href="https://www.rojgarlive.com/">
                    Rojgar Samachar
                </a>
            </li>
        </ul>
    </div>
</div>