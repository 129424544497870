import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
export function tokenGetter() {
  return localStorage.getItem("token");
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        //allowedDomains: ["localhost:5001", "localhost:5000", "localhost:5002", "localhost:5003", "localhost", "jnvstest.orpgujarat.com", "nvsemployeeportal.org", "https://nvsemployeeportal.org", "http://nvsemployeeportal.org"],
        allowedDomains: ["localhost:5001", "localhost:5000", "localhost:5002", "localhost:5003", "localhost","nvsemployeeportal.org", "http://nvsemployeeportal.org"],
        //disallowedRoutes: [""],
      }
    }),
    SharedModule,
  ],
  providers: [
  ], 
  bootstrap: [AppComponent]
})
export class AppModule { }
