<!-- Handle branch node menu items -->
<div class="dropdown-hover w-100" *ngIf="child.childMenuContents && child.childMenuContents.length > 0" ngbDropdown>
    <a class="dropdown-item fw-bold dropdown-toggle" href="javascript:;" ngbDropdownToggle
        [attr.id]="'navbar-user_dropdown' + child.id" role="button" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">
        {{child.name}}
    </a>
    <div [attr.aria-labelledby]="'navbar-user_dropdown' + child.id" ngbDropdownMenu class="dropdownhover-right">
    <div *ngFor=" let item of child.childMenuContents">
        <app-menu-item [child]=" item">
        </app-menu-item>
    </div>
</div>
</div>
<a *ngIf="!child.childMenuContents || child.childMenuContents.length === 0 && !child.isExternalLink" class="dropdown-item fw-bold"
    [routerLink]="[child.url]">
    <span>{{child.name}}</span>
</a>
<a *ngIf="!child.childMenuContents || child.childMenuContents.length === 0 && child.isExternalLink" class="dropdown-item fw-bold"
    [href]="child.url" target="_blank">
    <span>{{child.name}}</span>
</a>