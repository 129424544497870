<li class="nav-item" ngbDropdown *ngIf="isLoggedIn">
    <a class="nav-link d-flex align-items-center pr-0" href="javascript:;" ngbDropdownToggle role="button">
        <div class="d-flex justify-content-between align-items-center">
            <span class="rounded-circle bg-light text-dark px-2">{{ email | slice:0:1 | uppercase }}</span>
            <div class="ml-2">
                <span class="mb-0 text-sm  fw-bold">{{ email }}</span>
            </div>
        </div>
    </a>
    <div class="" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
        <a class="dropdown-item" *ngIf="isInRole('admin')" [routerLink]='["/admin"]'>Dashboard</a>
        <!-- <a class="dropdown-item" *ngIf="isInRole('user')" [routerLink]='["/employee"]'>Dashboard</a> -->
        <a class="dropdown-item" *ngIf="isInRole('user')" [routerLink]='["/employee/profile"]'>Dashboard</a>
        <a class="dropdown-item" *ngIf="isInRole('region')" [routerLink]='["/region"]'>Dashboard</a>
        <a class="dropdown-item" *ngIf="isInRole('school')" [routerLink]='["/school"]'>Dashboard</a>
        <a class="dropdown-item" *ngIf="isInRole('user')" [routerLink]='["/profile/contact"]'>Contact
            Details</a>
        <!-- <a class="dropdown-item" *ngIf="isInRole('user')" [routerLink]='["/profile/account"]'>Edit Account</a> -->
        <a class="dropdown-item" [routerLink]='["/profile/change-password"]'>Change Password</a>
        <div class="dropdown-divider"></div>
        <a href="javascript:;" class="dropdown-item" (click)="logout()">
            Logout
        </a>
    </div>
</li>
