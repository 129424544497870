import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class TransferRequestService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/transfer-requests', httpClient, toastrService);
  }

  logged() {
    return this.httpClient.get(this.baseUrl + 'api/transfer-requests/logged')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getByProfileId(profileId: string, financialYearId: number) {
    return this.httpClient.get(this.baseUrl + 'api/transfer-requests/id/' + profileId + '?financialYearId=' + financialYearId)
      .pipe(catchError(this.handleError.bind(this))) 
  }

  getEmployeeProfileByProfileId(profileId: string, financialYearId: number) {
    return this.httpClient.get(this.baseUrl + 'api/transfer-requests/employee-view/' + profileId + '?financialYearId=' + financialYearId)
      .pipe(catchError(this.handleError.bind(this)))
  }

  confirm() {
    return this.httpClient.post(this.baseUrl + 'api/transfer-requests/confirm', {}) 
      .pipe(catchError(this.handleError.bind(this)))
  }

  unconfirm() {
    return this.httpClient.post(this.baseUrl + 'api/transfer-requests/unconfirm', {})
      .pipe(catchError(this.handleError.bind(this)))
  }
  updateStatusPrincipal(resource) {
    return this.httpClient.post(this.baseUrl + 'api/transfer-requests/update-status', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  updateStatusRegion(resource) {
    return this.httpClient.post(this.baseUrl + 'api/transfer-requests/update-status-region', resource) 
      .pipe(catchError(this.handleError.bind(this)))
  }

  print(id) {
    const httpOptions = {
      responseType: 'blob' as 'json'    
    };

    let query = {  
      id: id
    }

    return this.httpClient.get(this.baseUrl + 'api/reports/print' + '?' + this.toQueryString(query), httpOptions)
      .pipe(catchError(this.handleError.bind(this))) 
  }

  getSchoolStatistic() {
    return this.httpClient.get(this.baseUrl + 'api/reports/school-statistic')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getRegionStatistic() {
    return this.httpClient.get(this.baseUrl + 'api/reports/region-statistic')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getProfileStatus(transferRequestId: number) {
    return this.httpClient.get(this.baseUrl + 'api/transfer-requests/profile-status/' + transferRequestId)
      .pipe(catchError(this.handleError.bind(this)))
  }

  createOtherDetailsTransferRequest(resource) {
    return this.httpClient.post(this.baseUrl + 'api/transfer-requests/other-details-create-transfer-request', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }
  updateOtherDtailsTransferRequest(id,resource) {
    return this.httpClient.put(this.baseUrl + 'api/transfer-requests/other-details-update-transfer-request' + '/' + id, resource)
      .pipe(catchError(this.handleError.bind(this)))
  }
  updatePreferenceTransferRequest(id,resource) {
    return this.httpClient.put(this.baseUrl + 'api/transfer-requests/preference-update-transfer-request' + '/' + id, resource)
      .pipe(catchError(this.handleError.bind(this)))
  }
  // updateDistrictUser(id: any, resource: any) {
  //   return this.httpClient.put(this.baseUrl + 'api/district-users/update' + '/' + id, resource)
  //     .pipe(catchError(this.handleError.bind(this)))
  // }
}
