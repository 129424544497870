<!-- <section class="bg-dark">
    <div class="container">
        <div class="d-flex justify-content-end align-items-between">
            <app-select-language></app-select-language>
        </div>
    </div>
</section> -->
<section class="pt-3 pb-2">
    <div class="container">
        <div class="d-flex justify-content-between align-items-between">
            <div class="d-flex justify-content-start align-items-center">
                <a class="navbar-brand" [routerLink]="['/']">
                    <img src="/assets/img/brand/nvs-black.png" style="height: 96px;" class="navbar-brand-img" alt="...">
                </a>
                <div class="ml-lg-2">
                    <h4 class="fw-bold text-default">NVS EMPLOYEE PORTAL</h4>
                    <h6 class="d-none d-sm-block">Navodaya Vidyalaya Samiti-(An Autonomous Body Under MoE) Government Of India</h6>
                </div>
            </div>
            <img src="/assets/img/brand/emblem-of-india-logo.png" style="height: 96px;" class="navbar-brand-img d-none d-sm-block" alt="...">
        </div>
    </div>
</section>